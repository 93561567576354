<template>
  <div id="manage-product-list">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.97"
      :z-index="2147480000"
    ></loading>
    <modal
      name="order-modal"
      class="top-modal self-order-modal"
      :height="'90%'"
      :width="'95%'"
    >
      <div id="order-modal" v-if="modalData && modalData.id" class="pd-1-em">
        <div class="modal-header fx-main fx-end">
          <i
            class="clickable fa fa-times"
            aria-hidden="true"
            v-on:click="hideModal"
          ></i>
        </div>

        <div @click="printOrder" class="mg-1-bot">
          <span class="btn">Imprimir pedido</span>
        </div>

        <div id="printMe">
          <div>
            <div class="wd-100" v-if="$store.state.user.role != 'STOREKEEPER'">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Código do pedido</th>
                    <th>Data do Pedido</th>
                  </tr>
                  <tr>
                    <td>{{ modalData.id }}</td>
                    <td>{{ modalData.created_at | formatDate }}</td>
                  </tr>
                </thead>
              </table>

              <div v-if="modalData.storekeeper.variation == 'COMPANY'">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Razão Social</th>
                      <th>Nome Fantasia</th>
                      <th>CNPJ</th>
                      <th>I.E</th>
                      <th>Atividade</th>
                    </tr>
                  </thead>

                  <tr>
                    <td>{{ modalData.storekeeper.company_name }}</td>
                    <td>{{ modalData.storekeeper.trading_name }}</td>
                    <td>{{ modalData.storekeeper.cnpj }}</td>
                    <td>{{ modalData.storekeeper.ie }}</td>
                    <td>{{ modalData.storekeeper.activity }}</td>
                  </tr>
                </table>

                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Comprador</th>
                      <th>CPF</th>
                      <th>Telefone</th>
                      <th>Email</th>
                    </tr>
                  </thead>

                  <tr>
                    <td>{{ modalData.storekeeper.name }}</td>
                    <td>{{ modalData.storekeeper.cpf }}</td>
                    <td>{{ modalData.storekeeper.phone | formatRemove55 }}</td>
                    <td>{{ modalData.storekeeper.email }}</td>
                  </tr>
                </table>
              </div>

              <div v-else>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Comprador</th>
                      <th>CPF</th>
                      <th>Telefone</th>
                      <th>Email</th>
                    </tr>
                  </thead>

                  <tr>
                    <td>{{ modalData.storekeeper.name }}</td>
                    <td>{{ modalData.storekeeper.cpf }}</td>
                    <td>{{ modalData.storekeeper.phone | formatRemove55 }}</td>
                    <td>{{ modalData.storekeeper.email }}</td>
                  </tr>
                </table>

                <hr />
              </div>
            </div>

            <div v-if="modalData && modalData.shipping_destination_address">
              <table class="table table-bordered">
                <thead>
                  <th colspan="8" class="txt-align-center">
                    Endereço de Entrega
                  </th>
                </thead>
                <thead>
                  <tr>
                    <th>Método Escolhido</th>
                    <th>CEP</th>
                    <th>Estado</th>
                    <th>Cidade</th>
                    <th>Bairro</th>
                    <th>Rua</th>
                    <th>N°</th>
                    <th>Complemento</th>
                  </tr>
                </thead>

                <tr>
                  <td>
                    <div v-switch="modalData.shipping_method">
                      <span v-case="'go_fetch'">Retirada na Loja</span>
                      <div v-case="'express_delivery'">
                        <span v-if="modalData.shipping_amount == 0">
                          Frete Grátis
                        </span>
                        <span v-else> Courier </span>
                      </div>
                      <span v-default>
                        {{ modalData.shipping_method | toShipping }}
                      </span>
                    </div>
                  </td>

                  <td>
                    {{ modalData.shipping_destination_address.zipcode }}
                  </td>
                  <td>
                    {{ modalData.shipping_destination_address.state }}
                  </td>
                  <td>
                    {{ modalData.shipping_destination_address.city }}
                  </td>
                  <td>
                    {{ modalData.shipping_destination_address.neighborhood }}
                  </td>
                  <td>
                    {{ modalData.shipping_destination_address.address }}
                  </td>
                  <td>
                    {{ modalData.shipping_destination_address.number }}
                  </td>
                  <td>
                    {{ modalData.shipping_destination_address.complement }}
                  </td>
                </tr>
              </table>
            </div>

            <div v-if="modalData && modalData.shipping_destination_address">
              <table class="table table-bordered">
                <thead>
                  <th colspan="8" class="txt-align-center">
                    Endereço de Faturamento
                  </th>
                </thead>
                <thead>
                  <tr>
                    <th>CEP</th>
                    <th>Estado</th>
                    <th>Cidade</th>
                    <th>Bairro</th>
                    <th>Rua</th>
                    <th>N°</th>
                    <th>Complemento</th>
                    <th>Observação</th>
                  </tr>
                </thead>
                <tr>
                  <td>
                    {{ modalData.shipping_destination_address.zipcode }}
                  </td>
                  <td>
                    {{ modalData.shipping_destination_address.state }}
                  </td>
                  <td>
                    {{ modalData.shipping_destination_address.city }}
                  </td>
                  <td>
                    {{ modalData.shipping_destination_address.neighborhood }}
                  </td>
                  <td>
                    {{ modalData.shipping_destination_address.address }}
                  </td>
                  <td>
                    {{ modalData.shipping_destination_address.number }}
                  </td>
                  <td>
                    {{ modalData.shipping_destination_address.complement }}
                  </td>
                  <td>
                    {{ modalData.shipping_destination_address.observation_message }}
                  </td>
                </tr>
              </table>
            </div>
 
            <div class="fx fx-direct-cont">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Meio de Pagamento</th>
                    <th>Status do pagamento</th>
                  </tr>
                </thead>

                <tr>
                  <td>
                    <span v-if="modalData.payment_method == 'credit_card'">
                      Cartão de Crédito  ( {{  modalData.payment_data.card_holder_name  }} - {{  modalData.payment_data.card_number.substring(modalData.payment_data.card_number.length - 4) }} ) &nbsp;&nbsp;  Parcelas: {{  modalData.payment_data.installments }}
                    </span>
                    <span v-if="modalData.payment_method == 'boleto'">
                      Boleto  
                    </span>
                    <span v-if="modalData.payment_method == 'pix'">
                      PIX  
                    </span>
                  </td>
                  <td>{{ modalData.status | toStatus }}</td>
                </tr>
              </table>
            </div>
          </div>

          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Imagem</th>
                <th>Nome do Produto</th>
                <th>Quantidade</th>
                <th>Preço</th>
              </tr>
            </thead>

            <!-- <div class="manage-product-img">
            <img :src="buildImgUrl(orderRow)" :alt="(orderRow.product || orderRow.variation).name" />
            </div>-->
   
            <tbody
              :key="
                `${orderRow.product.id}` +
                String(orderRow.variation ? orderRow.variation.id : '')
              "
              v-for="orderRow in modalData.items"
            >
              <tr>
                <td>
                <a :href="buildImgUrl(orderRow)"
                    target="_blank">
                  <img
                    :src="buildImgUrl(orderRow)"
                    :alt="orderRow.product ? orderRow.product.name : ''"
                    width='100'
                    height='100'
                />
                </a>
                </td>
                <td>
                  {{ orderRow.product.name }} - ( #{{ orderRow.product.id }} )
                  <template v-if="orderRow.variation"
                    >&nbsp;- {{ orderRow.variation.name }}</template
                  >
                </td>
                <td>
                  {{ orderRow.quantity }}
                </td>
                <td>
                  <!---------- Without Plotted Price ------------------------------------->
                  <template v-if="!modalData.enabled_plotted" >
                  <span class="product-price">
                    {{ orderRow.unit_price | toCurrency }}
                  </span>
                  </template>
                  <!---------- Plotted Price --------------------------------------------->
                  <template v-if="modalData.enabled_plotted" >
                  <span class="product-price">
                    {{ orderRow.product.plotted_price | toCurrency }}
                  </span>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Valor total dos produtos</th>
                <th>Valor total do frete</th>
                <th v-if="modalData.coupons.length != 0">Descontos</th>
                <th v-if="(modalData.enabled_purchase_discount && modalData.items_amount >= modalData.purchase_amount)">Desconto Dia das Mães</th>
                <th>Valor total do pedido</th>
              </tr>
            </thead>

            <tr>
              <!-- <td>{{ modalData.items_amount | toCurrency }}</td> -->

              <td>
                  <!---------- Without Plotted Price ------------------------------------->
                  <template v-if="!modalData.enabled_plotted" >
                   {{ modalData.items_amount | toCurrency }}
                  </template>
                  <!---------- Plotted Price ----------------------------->
                  <template v-if="modalData.enabled_plotted" >
                   {{ parseInt(modalData.items_amount) + parseInt(modalData.plotted_amount) | toCurrency }}
                  </template>
 
              </td>


              <td>{{ modalData.shipping_amount | toCurrency }}</td>
          
              <td v-if="modalData.coupons.length != 0">

                
                  <template
                    v-if="modalData.coupons[0].modifier_type === 'PERCENTAGE'"
                  >
                    <span class="main-product-title">
                      Cupom ( {{ modalData.coupons[0].code }} -
                      {{ modalData.coupons[0].amount }} % )
                    </span>
                  </template>
                  <template
                    class="main-product-title"
                    v-if="modalData.coupons[0].modifier_type === 'PRICE'"
                  >
                    <span class="main-product-title">
                      - {{ modalData.coupons[0].amount | toCurrency }} ({{
                        modalData.coupons[0].code
                      }})</span
                    >
                  </template>
                
                
              </td>


              <td v-if="(amountDiscount(modalData) > 0)">
                {{ amountDiscount(modalData)  | toCurrency }}
              </td> 
  
             <td>
                  <!---------- Without Plotted Price --------------------->
                  <template v-if="!modalData.enabled_plotted" >
                  {{ modalData.full_amount - amountDiscount(modalData)| toCurrency }} 
                  </template>
                  <!---------- Plotted Price ----------------------------->
                  <template v-if="modalData.enabled_plotted" >
                  {{ parseInt(modalData.full_amount) + parseInt(modalData.plotted_amount) - amountDiscount(modalData)| toCurrency }} 
                  </template>
              
              </td>

            </tr>
          </table>

          <!-- <div
              v-for="related_file in (((orderRow.variation || orderRow.product).related_files || []).filter(r => r.buyable))"
              :key="related_file.id"
              class="fx-main default-border fx-a-i-cent shadow order-list"
            >
              {{ related_file.name }}</span>
              <span
                v-if="related_file.description && related_file.description.length"
              >{{ related_file.description }}</span>
              <span v-if="related_file.is_loading">
                <strong>Baixando...</strong>
              </span>
              <span v-if="related_file.blob_url">
                <strong>Baixado! Clique novamente para salvar.</strong>
              </span>
              <a
                :download="related_file.blob_url ? related_file.name : false"
                @click="popBlobIfNot(orderRow.product.id, orderRow.variation ? orderRow.variation.id : null, related_file)"
                :href="related_file.blob_url || 'javascript:void(0)'"
              >
                <template v-if="related_file.blob_url">Salvar</template>
                <template v-else>Baixar</template> Arquivo
              </a>
          </div>-->
        </div>

        <!-- $store.state.user.role === 'STOREKEEPER' && -->

        <a
          v-if="
            modalData.status === 'AWAITING_PAYMENT' && modalData.payment_link
          "
          class="btn clickable"
          :href="modalData.payment_link"
          target="_blank"
          >Ver Boleto</a
        >
        <a
          v-if="modalData.status === 'AWAITING_PAYMENT' && modalData.pix_qr_code"
          >Efetivar o Pagamento no PIX</a
        >
        <div class="fx fx-center" v-if="modalData.status === 'AWAITING_PAYMENT' && modalData.pix_qr_code">
        <div class="mt-3 mb-3">
          <span> Escaneie esse código para pagar </span>
        <div class="mt-3">
          <qrcode :background="background" :size="size" :cls="qrCls" :value="modalData.pix_qr_code"></qrcode>
        </div>
        <div>
          <textarea 
            class="textarea" 
            v-model="modalData.pix_qr_code"
            ref="copiar"> 
          </textarea>
        </div>
        <div class="fx fx-center mt-3">
          <span> Ou copie esse código QR para fazer o pagamento__</span>
          <span @click="copy" class="fa fa-copy" title="Copiar"> __{{ this.msg_pix }} </span>
        </div>
        </div>
        </div>
        <div v-if="$store.state.user.role === 'SUPPLIER'">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Status do Pedido</th>
                <th v-if="modalData.supplier_entries[0].tracking_code">
                  Código de Rastreio / Mensagem ao Cliente
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th>
                  {{ modalData.supplier_entries[0].sub_status | toStatus }}
                </th>
                <th v-if="modalData.supplier_entries[0].tracking_code">
                  {{ modalData.supplier_entries[0].tracking_code }}
                </th>
              </tr>
            </tbody>
          </table>

          <!-- <template v-if=" modalData.supplier_entries && modalData.supplier_entries[0].sub_status == 'PROCESSING'" > -->
          <template v-if="modalData.supplier_entries">
            <div class="input-style mg-1-bot">
              <input
                type="text"
                class="default-border"
                id="input_tracking_code"
                v-model="input_tracking_code"
                v-if="modalData.supplier_entries[0].sub_status == 'PROCESSING'"
                placeholder="código de rastreio / mensagem ao cliente"
              />
            </div>

            <button
              class="btn clickable"
              type="button"
              @click="updateOrderStatus('ON_COURSE')"
              v-if="
                modalData.supplier_entries[0].sub_status == 'PROCESSING' &&
                modalData.shipping_method != 'go_fetch'
              "
            >
              Postar Produto
            </button>

            <button
              class="btn clickable"
              type="button"
              @click="updateOrderStatus('COMPLETED')"
            >
              Pedido Entregue ao Cliente
            </button>

            <a
              :href="
                !isMobile
                  ? 'https://web.whatsapp.com/send?phone=' +
                    modalData.storekeeper.phone
                  : `whatsapp://send?phone=${modalData.storekeeper.phone}`
              "
              rel="noopener noreferrer"
              target="_blank"
              class
            >
              <button class="btn clickable" type="button">
                <i class="fab fa-whatsapp" />
                Entrar em Contato
              </button>
            </a>

            <button
              class="btn clickable error"
              type="button"
              @click="updateOrderStatus('CANCELLED')"
            >
              Cancelar Pedido
            </button>

            <button
              class="btn clickable error"
              type="button"
              @click="printLabel()"
            >
              Etiqueta Correio
            </button>

            <button
              class="btn clickable error"
              type="button"
              @click="showModalRastreio()"
            >
              Consulta Correio
            </button>

              <button
              class="btn clickable error"
              type="button"
              @click="showModalEtiqueta(modalData.id)"
            >
              Escolher Etiqueta
            </button>

            <button
              class="btn clickable error"
              type="button"
            >
            <a href='https://www.correios.com.br/enviar/encomendas/arquivo/nacional/formulario-declaracao-de-conteudo-a5' target='_blank' style='color: white;'>
              Imprimir declaração de Conteúdo
            </a>
              
            </button>

            

          </template>
          <div
            class="ordercomments fx-main fx-direct-cont"
            v-if="order_comments_enabled"
          >
            <div
              class="ordercomment"
              v-for="comment in modalData.supplier_entries[0].comments"
              :key="comment"
            >
              <div>
                Comentário de
                {{
                  comment.is_supplier
                    ? modalData.supplier_entries[0].supplier.name
                    : modalData.storekeeper.name
                }}:
              </div>
              <div>{{ comment.text }}</div>
            </div>
          </div>
          <div
            class="comment-submit-order"
            v-if="$store.state.user.role != 'ADMIN' && order_comments_enabled"
          >
            <textarea v-model="commentText" />
            <button
              class="btn clickable"
              @click="submitComment(modalData.supplier_entries[0].supplier.id)"
            >
              Enviar Comentário
            </button>
          </div>
        </div>
        <div class="fx-main mg-1-top" v-else-if="modalData.supplier_entries">
          <div
            class="fx-main fx-direct-cont order-list grid-space default-border"
            v-for="supplier_entry in modalData.supplier_entries"
            :key="supplier_entry.supplier.id"
          >
            <div>
              Status
              <template v-if="!assumeSingleSupplier"></template>
              : {{ supplier_entry.sub_status | toStatus }}
            </div>
            <div class="grid-space">
              <router-link :to="{ name: 'Help' }" class="btn clickable">
                Entrar em Contato com a Loja
              </router-link>
            </div>
            <div v-if="supplier_entry.tracking_code">
              Código de Rastreio: {{ supplier_entry.tracking_code }}
            </div>
            <div class="ordercomments" v-if="order_comments_enabled">
              <div
                class="ordercomment"
                v-for="comment in supplier_entry.comments"
                :key="comment"
              >
                <div>
                  <b
                    >Comentário de
                    {{
                      comment.is_supplier
                        ? supplier_entry.supplier.name
                        : modalData.storekeeper.name
                    }}:</b
                  >
                </div>
                <div>{{ comment.text }}</div>
              </div>
            </div>
            <div
              class="comment-submit-order"
              v-if="$store.state.user.role != 'ADMIN' && order_comments_enabled"
            >
              <textarea class="default-border" v-model="commentText" />
              <button
                class="btn clickable"
                @click="submitComment(supplier_entry.supplier.id)"
              >
                Enviar Comentário
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="order-modal-rastreio"
      class="top-modal self-order-modal"
      :height="'90%'"
      :width="'95%'"
    >
      <div id="order-modal" v-if="modalData && modalData.id" class="pd-1-em">
        <div class="modal-header fx-main fx-end">
          <i
            class="clickable fa fa-times"
            aria-hidden="true"
            v-on:click="hideModalRastreio"
          ></i>
        </div>

        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Status do Pedido</th>
              <th v-if="modalData.supplier_entries[0].tracking_code">
                Código de Rastreio / Mensagem ao Cliente
              </th>
            </tr>
          </thead>

          <tbody >
            <tr>
              <th>
                {{ modalData.supplier_entries[0].sub_status | toStatus }}
              </th>
              <th v-if="modalData.supplier_entries[0].tracking_code">
                {{ modalData.supplier_entries[0].tracking_code }}
              </th>
            </tr>
          </tbody>
        </table>

      <div
        v-for="status in modalData.supplier_entries[0].tracking_data"
        :key="`${status.data}`"
      >
        <template>
          
          <div class="order-line-list">
            <span class="product-name">
              <b>Data</b>
            </span>
            <span class="product-name">{{ status.data}}</span>
          </div>
          <div class="order-line-list">
            <span class="product-name">
              <b>Local</b>
            </span>
            <span v-html="status.local"></span>
          </div>
          <div class="order-line-list">
            <span class="product-name">
              <b>Status</b>
            </span>
            <span class="product-name">{{ status.status}}</span>
          </div>

        </template>
      </div>

      </div>
    </modal>

    <modal
      name="order-modal-etiqueta"
      class="top-modal self-order-modal"
      :height="'60%'"
      :width="'60%'"
    >
      <div class="modal-header fx-main fx-end">
        <i
          class="clickable fa fa-times"
          aria-hidden="true"
          v-on:click="hideModalEtiqueta"
        ></i>
      </div>
      <div id="order-modal-etiqueta" v-if="serviceList" class="pd-1-em">
        <label class="mg-right-2-em" for="form_servicos">Escolha um dos Serviços Contratados</label>
        <select
          name="serviceList"
          multiple="multiple"
          size=10
          class="border-radius-10 pd-0 scrollbar-none select-style"
          id="form_servicos"
          v-model="modalDataServices"
        >
          <option
            v-for="service in serviceList"
            :key="service.codigo"
            v-bind:value="service.codigo"
          >
          {{ service.descricao }}
          </option>
          
        </select>
      </div>
      <button
        class="btn clickable error"
        type="button"
        @click="printLabel()"
      >
        Imprimir Etiqueta
      </button>
      <button
        class="btn clickable error"
        type="button"
        @click="printPlp()"
      >
        Imprimir PLP
      </button>
    </modal>

    <div class="style-mobile wd-100 main-grid g-t-c-1" ref="plistRef">
      <div
        v-for="order in productList"
        :key="`${order.id}`"
        class="client-card fx-main manage-product-item default-border fx-a-i-cent shadow pd-2-em gd-tp-c gd-tp-r j-i clickable panel-view"
        @click="showModal(order.id)"
      >
        <template v-if="order.supplier_entries[0].sub_status != 'CANCELLED'">
          <div class="order-line-list">
            <span class="product-name">
              <b>Código Pedido</b>
            </span>
            <span class="product-name">{{ order.id }}</span>
          </div>
          <div
            class="order-line-list pd-10-px data-fs"
            v-if="order.storekeeper"
          >
            <span class="product-name">
              <b> Nome </b>
            </span>
            <span class="product-name txt-transf">{{
              order.storekeeper.name
            }}</span>
          </div>
          <div class="order-line-list">
            <span class="product-name">
              <b>Data</b>
            </span>

            <span class="product-name txt-align-center">
              {{ order.created_at | formatDateTime }}
            </span>
          </div>

          <div class="order-line-list">
            <span class="product-name">
              <b>Pagamento</b>
            </span>

            <span
              class="product-name"  v-if="order.payment_method == 'credit_card'" >
              Cartão de Crédito</span>
            <span
              class="product-name"  v-if="order.payment_method == 'boleto'" >
              Boleto</span>
            <span
              class="product-name"  v-if="order.payment_method == 'pix'" >
              PIX</span>
       
          </div>

          <div class="order-line-list">
            <span class="product-name"> Valor </span>
            <!---------- Without Plotted Price --------------------------->
            <span v-if="!order.enabled_plotted" class="product-name">
              {{ order.full_amount - amountDiscount(order) | toCurrency }}
            </span>
            <!---------- Plotted Price ----------------------------------->
            <span v-if="order.enabled_plotted" class="product-name">
              {{ (parseInt(order.full_amount) + parseInt(order.plotted_amount)) - amountDiscount(order) | toCurrency }}
            </span>
          </div>

          <div class="order-line-list">
            <span class="product-name">
              <b>Entrega</b>
            </span>

            <div class="product-name" v-switch="order.shipping_method">
              <div v-case="'express_delivery'">
                <span v-if="order.shipping_amount == 0"> Frete Grátis </span>
                <span v-else> Courier </span>
              </div>
              <span v-default> {{ order.shipping_method | toShipping }} </span>
            </div>
          </div>

          <div class="order-line-list">
            <span class="product-name"> Status Pagamento </span>

            <!--   AWAITING_PAYMENT: "Aguardando Pagamento",
            PROCESSING: "Processando",
            AUTHORIZED: "Autorizado",
            PAID: "Pago",
            REFUND_OK: "Reembolsado",
            REFUND_PENDING: "Reembolso Pendente",
            DENIED: "Negado",
            REFUSED: "Recusado",
            ON_COURSE: "A Caminho",
            COMPLETED: "Completo",
            
    CANCELLED: "Cancelado",-->
            <template class="order-line-list">
              <div v-switch="order.status">
                <span v-case="'PAID'" class="wd-100 status-btn success">
                  {{ order.status | toStatus }}</span
                >
                <span
                  v-case="'AWAITING_PAYMENT'"
                  class="wd-100 status-btn error"
                >
                  {{ order.status | toStatus }}
                </span>
                <span v-case="'COMPLETED'" class="wd-100 status-btn success">
                  {{ order.status | toStatus }}
                </span>
                <span v-case="'CANCELLED'" class="wd-100 status-btn error">
                  {{ order.status | toStatus }}
                </span>
                <span v-case="'REFUND_OK'" class="wd-100 status-btn refunded">
                  {{ order.status | toStatus }}
                </span>

                <span class="wd-100" v-default>
                  {{ order.status | toStatus }}
                </span>
              </div>
            </template>
          </div>

          <div class="order-line-list">
            <span class="product-name">
              <b>Status Pedido </b>
            </span>

            <div></div>

            <!--   AWAITING_PAYMENT: "Aguardando Pagamento",
            PROCESSING: "Processando",
            AUTHORIZED: "Autorizado",
            PAID: "Pago",
            REFUND_OK: "Reembolsado",
            REFUND_PENDING: "Reembolso Pendente",
            DENIED: "Negado",
            REFUSED: "Recusado",
            ON_COURSE: "A Caminho",
            COMPLETED: "Completo",
            
    CANCELLED: "Cancelado",-->
            <div>
              <div v-switch="order.supplier_entries[0].sub_status">
                <span class="status-btn success" v-case="'COMPLETED'">
                  Entregue
                </span>
                <span class="status-btn processing" v-case="'ON_COURSE'">
                  {{ order.supplier_entries[0].sub_status | toStatus }}
                </span>
                <span v-case="'PROCESSING'">
                  <!-- {{ order.supplier_entries[0].sub_status | toStatus }} -->
                  <div v-switch="order.status">
                    <span class="status-btn error" v-case="'AWAITING_PAYMENT'">
                      Aguardando
                    </span>
                    <span class="status-btn careful" v-case="'CANCELLED'">
                      {{ order.supplier_entries[0].sub_status | toStatus }}
                    </span>
                    <span
                      class="status-btn careful refunded"
                      v-case="'REFUND_OK'"
                    >
                      Reembolsado
                    </span>
                    <span class="status-btn careful" v-default>
                      {{ order.supplier_entries[0].sub_status | toStatus }}
                    </span>
                  </div>
                </span>
                <span class="status-btn error" v-case="'CANCELLED'">
                  {{ order.supplier_entries[0].sub_status | toStatus }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

  <style scoped>
.order-list {
  border-radius: 2em;
  margin-bottom: 1em;
}

.main-grid {
  grid-template-columns: 1fr !important;
}

.manage-product-img {
  width: 5em;
}
.manage-product-img img {
  max-width: 100%;
}
.grid-space {
  display: grid;
  grid-gap: 1em;
}

.pd-10-px {
  padding: 10px;
}

@media (max-width: 500px) {
  .pd-10-px {
    padding: 10px;
  }
  .wd-100 {
    overflow: scroll;
  }

  .style-mobile {
    width: 100%;
    display: block;
  }

  .style-modal h2 {
    font-size: 1.1rem;
  }
}
.pd-10-px p {
  padding: 0;
  margin: 0;
}

.status-btn {
  padding: 0.5em;
  border-radius: 2em;
}

.error {
  background-color: #c92323c4;
}

.success {
  background-color: #1e7b2140;
}

.processing {
  background-color: #086bff40 !important;
}

.careful {
  background-color: #e4e624 !important;
}
.refunded {
  background-color: purple !important;
}

.client-card {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
  margin-top: 2em !important;
}

.client-card .product-name {
  font-weight: 999;
  letter-spacing: 1px;
  font-size: 1.2em;
}

.client-card {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr 9em 1fr 1fr 1fr !important;
  padding: 0.5em 0;
  background: #ececec;
  border-radius: 2em;
}

.client-card.fx-main.manage-product-item.default-border.fx-a-i-cent.shadow.pd-2-em.gd-tp-c.gd-tp-r.j-i.clickable
  div.order-line-list {
  border-right: 1px solid black !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.panel-view {
  width: 90%;
  margin: 0 auto;
}
.table thead th {
  background-color: green;
  color: white !important;
}
</style>

<script>
import Order from "@/services/order.js";
import User from "@/services/user.js";
import util from "@/services/util";
import store from "@/store.js";
import { debounce } from "debounce";
import boolean from "boolean";
import { buildImgUrl } from "@/services/item";
import HeaderBuilder from "@/services/header-builder.js";
import { apiBaseUrl } from "@/services/api.js";
import axios from "axios";
import { vSwitch, vCase, vDefault } from "v-switch-case";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet

import Qrcode from 'v-qrcode/src/index';

export default {
  name: "OrderList",
  props: {
    order_id: String,
  },
  directives: {
    switch: vSwitch,
    case: vCase,
    default: vDefault,
  },

  components: {
    Loading,
    Qrcode,
  },
  data: () => {
    return {
      msg_pix: null,
      qrCls: 'qrcode',
      size: 250,
      background: '#eeeeee',
      productList: [],
      serviceList: [],
      loadingMore: false,
      loadIndex: 0,
      over: false,
      productModal: {},
      isMobile: false,
      isLoading: false,
      orders: {
        autoId: 1000,
      },
      modalData: {},
      modalDataServices: [],
      commentText: "",
      input_tracking_code: "",
      order_comments_enabled: boolean(
        process.env.VUE_APP_ORDER_COMMENTS_ENABLED
      ),
      statusOrder: null,
      searchParameters: {
        page: 0,
        perPage: 10,
        price_le: 0,
        price_ge: 0,
        ob_price: false,
        convert_prices: true,
      },
    };
  },
  mounted() {
    Order.list().then((order) => {
      this.order = order;
    });

    this.listProducts();
    // Add event listener for search parameter change (navbar search usually)
    this.$root.$on("searchParameters", this.onSearchParametersEvent);
    this.$root.$emit("openSearch");
    setTimeout(() => window.addEventListener("scroll", this.handleScroll), 500);
    if (this.order_id) {
      this.showModal(this.order_id);
    }
  },
  methods: {
    copy: function() {
      var copyTextarea = this.$refs.copiar
      copyTextarea.select();
      try {
        var successful = document.execCommand('copy');
        this.msg_pix = "copiado!";
      } catch (err) {
          this.msg_pix = "não foi copiado!";
      }
    },
    onSearchParametersEvent: function (searchParameters) {
      this.$root.$emit("searchListened");
      Object.assign(this.searchParameters, searchParameters);
      this.searchParameters.page = 0;
      this.loadingMore = false;
      this.over = false;
      this.listProducts();
    },

    listProducts(push = false) {
      let searchParameters = {
        page: 0,
        perPage: 30,
        price_le: 0,
        price_ge: 0,
        ob_price: false,
        convert_prices: true,
      };
      if (push) {
        if (this.loadingMore || this.over) {
          return;
        }
        this.searchParameters.page++;
        this.loadingMore = true;
      } else {
        this.searchParameters.page = 0;
        this.loadingMore = false;
        this.over = false;
        this.loadIndex++;
      }

      Order.list(this.searchParameters)
        .then((products) => {
          products.forEach((product) => {
            this.orders = product;
          });
          if (!push) {
            this.productList = products;
          } else {
            if (products.length === 0) {
              this.over = true;
            }
            this.productList.push(...products);
            this.$forceUpdate();
            setTimeout(() => (this.loadingMore = false), 100);
          }
        })
        .finally(() => {
          if (!push) {
            this.loadIndex--;
          }
        });
    },
    amountDiscount (order) {
      if(!order.enabled_purchase_discount){
          return 0;
      }
      if(order.items_amount < order.purchase_amount){
          return 0;
      }
      let newFullAmount = order.items_amount;
      if(order.coupons.length != 0){
        if(order.coupons[0].modifier_type === 'PERCENTAGE'){
          newFullAmount = order.items_amount / (1 - (order.coupons[0].amount/100));
        } else{
          newFullAmount = order.items_amount + order.coupons[0].amount;
        }
      }
      return (newFullAmount * order.discount_percentage) + 0.005;
    },
    loadOrders: function () {
      
      this.isLoading = true;

      Order.list()

        .then((orders) => {
          this.orders = orders;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleScroll(event) {
      if (!this.$refs.plistRef) {
        return;
      }
      let screenHeight = screen.height;
      let scrollTop = document.documentElement.scrollTop;
      let productListStart = util.findPos(
        this.$refs.plistRef,
        document.documentElement
      ).y;
      let productListEnd = productListStart + this.$refs.plistRef.scrollHeight;
      let diff = productListEnd - scrollTop;
      let bottomOfWindow = diff <= screenHeight * 1.2;
      if (bottomOfWindow) {
        this.listProducts(true);
      }
    },
    printOrder() {
      // Pass the element id here
      this.$htmlToPaper("printMe");
    },
    showModal: function (orderId) {
      this.isLoading = true;

      Order.get(orderId)

        .then((order) => {
          this.modalData = order;
          order.items.forEach((entry) => {
            User.get(entry.product.supplier_id).then((supplier) => {
              entry.product.supplier = supplier;
              this.$forceUpdate();
            });
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
      // Reset comment text in case another modal was open before
      this.commentText = "";
      this.$modal.show("order-modal");
    },
    showModalRastreio: function () {
     // Reset comment text in case another modal was open before
      this.commentText = "";
      this.$modal.show("order-modal-rastreio");
    },
    showModalEtiqueta: function (orderId) {
      this.isLoading = true;
      Order.servicesLabel(orderId)
        .then((services) => {
          this.serviceList = services;
        })
        .finally(() => {
          this.isLoading = false;
        });
     // Reset comment text in case another modal was open before
      this.commentText = "";
      this.$modal.show("order-modal-etiqueta");
    },
    hideModal: function () {
      this.$modal.hide("order-modal");
    },
    hideModalRastreio: function () {
      this.$modal.hide("order-modal-rastreio");
    },
    hideModalEtiqueta: function () {
      this.$modal.hide("order-modal-etiqueta");
    },
    buildImgUrl: function (item) {
      return buildImgUrl(item);
    },
    popBlobIfNot: function (productId, variationId, related_file) {
      if (related_file.blob_url || related_file.is_loading) {
        return;
      }
      related_file.is_loading = true;
      this.$forceUpdate();
      const apiUrl =
        `${apiBaseUrl}/api/order/${this.modalData.id}/product/${productId}/related_file/${related_file.id}?apartment=${process.env.VUE_APP_TENANT_ID}` +
        (variationId ? `&variation_id=${variationId}` : "");

      axios
        .get(apiUrl, {
          responseType: "blob",
          headers: HeaderBuilder.build(),
        })
        .then(
          (response) => {
            related_file.blob_url = window.URL.createObjectURL(response.data);
            this.$forceUpdate();
          },
          (error) => {
            console.error(error);
          }
        )
        .finally(() => {
          related_file.is_loading = false;
          this.$forceUpdate();
        });
    },
    printLabel: function () {
      
      this.isLoading = true;
      const apiUrl =
        `${apiBaseUrl}/api/order-label/${this.modalData.id}?servico=${this.modalDataServices[0]}&&apartment=${process.env.VUE_APP_TENANT_ID}`;
      axios
        .get(apiUrl, {
          responseType: "blob",
          headers: HeaderBuilder.build(),
        })
          .then(response => {
            const url = window.URL.createObjectURL(response.data);
            window.open(url);
            this.$forceUpdate();
          }
          
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
    printPlp: function () {
      this.isLoading = true;
      const apiUrl =
        `${apiBaseUrl}/api/order-plp/${this.modalData.id}?apartment=${process.env.VUE_APP_TENANT_ID}`;
      axios
        .get(apiUrl, {
          responseType: "blob",
          headers: HeaderBuilder.build(),
        })
          .then(response => {
            const url = window.URL.createObjectURL(response.data);
            window.open(url);
            this.$forceUpdate();
          }
          
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateOrderStatus: function (status) {
      this.isLoading = true;
      Order.updateStatus(this.modalData.id, status, {
        tracking_code: this.input_tracking_code,
      })
        .then(
          (response) => {
            this.$notify({
              duration : 24000,
              type: "success",
              title: "Status Alterado",
              text: "Status foi alterado com sucesso!",
            });

            this.modalData.supplier_entries[0] = response;
            this.$forceUpdate();
          },
          (errorResponse) => {
            this.$notify({
              duration : 24000,
              type: "error",
              title: "Erro ao Alterar Status",
              text: util.stringifyAxiosError(errorResponse),
            });
          }
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
    submitComment: debounce(
      function (supplierId) {
        this.isLoading = true;
        let text = this.commentText;
        this.commentText = "";
        Order.submitComment(this.modalData.id, text, supplierId)
          .then(
            (response) => {
              this.modalData.supplier_entries = this.modalData.supplier_entries.map(
                (entry) => {
                  if (entry.supplier.id != supplierId) {
                    return entry;
                  }
                  if (!entry.comments) {
                    entry.comments = [];
                  }
                  entry.comments.push({
                    text: text,
                    is_supplier: store.state.user.role === "SUPPLIER",
                  });
                  return entry;
                }
              );
              this.$forceUpdate();
            },
            (errorResponse) => {
              this.commentText = text;
              this.$notify({
                duration : 24000,
                type: "error",
                title: "Erro ao Enviar Comentário",
                text: util.stringifyAxiosError(errorResponse),
              });
            }
          )
          .finally(() => {
            this.isLoading = false;
          });
      },
      1000,
      true
    ),
  },
};
</script>